<template>
  <section class="tables">
    
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Liste des visites</div>
            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="/addvisite">
                  <li class="breadcrumb-item btn btn-primary">
                    Affecter une visite
                  </li>
                </router-link>
              </ol>
            </nav>
            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par nom "
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Nom</th>
                    <th scope="col">Délélgué</th>
                    <th scope="col">Zone</th>
                    <th scope="col">Produit</th>
                   
                    <!-- <th scope="col">Specialité</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(visite, index) in visites" :key="index">
                    <td>{{ visite.nom }} </td>
                     <td v-if="visite.user_id">{{ visite.user_id["name"] }}</td>
                     <td v-if="visite.user_id">{{ visite.zone_id["intitule"] }}</td>  
                     <!-- <td>{{ visite.produit_id[0].designation}}</td> -->
                      
                          
                 
                    <!-- <td>{{ visite.specialite }}</td> -->
                       
                     <td>
                             <!-- <router-link
                      :to="{ name: 'updatevisite', params: { id: visite._id } }"
                      ><i
                        class="mdi mdi-google-maps icone"
                        style="color:black"
                      ></i> </router-link
                    > -->
                      


                      <a :href="$router.resolve({name:'updatevisite', params: {id: visite._id}}).href">
                        <i
                        class="mdi mdi-google-maps icone"
                        style="color:black"
                      ></i>
                      </a>
                   
                     
                                          <v-icon
                        
                        color="blue darken-2"
                         data-toggle="modal"
                           :data-target="'#test' +visite._id"
                           
                          @click="sendproduit(visite)"
                      >
                        mdi-message-text
                      </v-icon>
                   
                      

                        <i
                          class="mdi mdi-check-circle icone text-success"
                          title="Valider la commande"
                          @click="valide(visite._id)"
                          v-if="visite.etat == 0"
                          style="font-size:50px"
                        >
                        </i>
                        <i
                          class="mdi mdi-close-octagon icone text-danger"
                          title="Refuser la commande"
                          @click="deletevisite(visite._id)"
                          v-if="visite.etat == 0"
                        ></i>
                         <v-chip
                             v-if="visite.etat == 1"
                            color="teal"
                            text-color="white"
                            style="color:green;margin-left:10px"
                          >
                            <v-avatar left>
                              <v-icon>mdi-checkbox-marked-circle</v-icon>
                            </v-avatar>
                            Confirmed
                          </v-chip>
                         <!-- Modal -->
                        <div
                          class="modal fade"
                          :id="'test' + visite._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                  <span style="font-size: 13px"
                                    >Visite No = {{selectedvisite.nom}} </span
                                  >
                                  <b></b>
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 10px; margin-bottom: 20px"
                                >
                                  <h5>Les informations du visite :</h5>
                                </div>
                                <table class="table table-bordered">
  
                                  <tr>
                                    <td><strong>délégué</strong></td>
                              <th  v-if="selectedvisite.user_id">{{ selectedvisite.user_id["name"] }}</th> 
                                  </tr>
                                 
                                  <tr>
                                    <td><strong>specialite</strong></td>
                                    <th>{{ selectedvisite.specialite }}</th>
                                  </tr>
                                  <tr>
                                    <td> <strong>zone</strong> </td>
                                <th v-if="selectedvisite.user_id">{{selectedvisite.zone_id["gouvernerat"] }}</th> 
                                  </tr>
                                 

                                
                                </table>

                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les events du visite</h5>
                                </div>
                                 <v-expansion-panels>
                                <v-expansion-panel
                                  v-for="(
                                        produit, index
                                      ) in selectedvisite.events"
                                      :key="index"
    >
                                            <v-expansion-panel-header>
                                              <strong> + {{produit.nom_client}}</strong>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                              <div  class="row">
                                                       <div class="my-4 text-subtitle-1" style="margin-right:10px;">
                                                <i
                                                                class="mdi mdi-map-marker-radius "
                                                                
                                                              
                                                                style="font-size:22px;margin-bottom:10px;"
                                                              >
                                                              </i> : <span v-html="produit.zone"></span>
                                            </div>
                                              <div class="my-4 text-subtitle-1" style="margin-right:10px;">
                                                <i
                                                                class="mdi mdi-calendar-check"
                                                                
                                                              
                                                                style="font-size:22px;margin-bottom:10px;"
                                                              >
                                                              </i> :<span v-html="produit.date"></span>  
                                            </div>
                                              <div class="my-4 text-subtitle-1" style="margin-right:10px;">
                                                <i
                                                                class="mdi mdi-basket"
                                                                
                                                              
                                                                style="font-size:22px;margin-bottom:10px;"
                                                              >
                                                              </i> : <span v-html="produit.zone"></span>
                                            </div>
                                              <div class="my-4 text-subtitle-1" style="margin-right:10px;">
                                                <i
                                                                class="mdi mdi-alarm-check"
                                                                
                                                              
                                                                style="font-size:22px;margin-bottom:10px;"
                                                              >
                                                              </i> : <span v-html="produit.duration"></span>  
                                            </div>
                                              </div>
                                           
                                            
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>

                                     <!-- <GmapMap
                                    :center="center"
                                    :zoom="zoom"
                                    style="width: 100%; height: 400px; margin-top: 20px"
                                  >
                                  <DirectionsRenderer
                                  travelMode="DRIVING"
                                  :origin="startLocation"
                                  :destination="endLocation"
                                  :waypoints="waypnt"
                                  :optimizeWaypoints="true"
                                />
                                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                                   </gmap-info-window>
                                  <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                    :icon="m.markerOptions"
                                    
                                    :clickable="true"
                                     @click="toggleInfoWindow(m,index)"
                                  />
                               
                                 
                                  </GmapMap> -->
                              </div>
                            
                            </div>
                          </div>
                        </div>
                  </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DirectionsRenderer from "@/views/DirectionsRenderer";
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  components: {
    DirectionsRenderer,
  },
  data() {
    return {
   
       markers: [],
       zoom:9,
      center: { lat: 36.794895, lng: 10.170715 },
      currentPlace: null,
      visites: [],
      search: "",
       selectedvisite: "",
       ////infomarker////
        infoWindowPos: null,
          infoWinOpen: false,
          currentMidx: null,
           infoOptions: {
        	content: '',
           
            pixelOffset: {
              width: 50,
              height: -35
            }
          },
          ///////wypoints
      startLocation: null,
      endLocation: null,
      waypnt: [],
       
    };
  },

  created() {
    this.getvisites();
  },
      mounted() {
    // this.locateGeoLocation();
  
   
  },
  computed: {
    // searchFunction() {
    //   return this.visites.filter((item) => {
    //     return item.nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
    //   });
    // },
  },
  methods: {
      locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        let  lat= res.coords.latitude
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
        //    const markerpos = {
        //       lat: res.coords.latitude,
        //       lng: res.coords.longitude
             
        // };
        //  this.markers.push({ position: markerpos });
        //  console.log( lat)
      });
    },
      sendproduit(item) {
        this.startLocation=null;
           navigator.geolocation.getCurrentPosition(res => {
            const textpos='blastek'
          const  markerOptionspos= {
      url: 'http://dev.sicomedia.ro/cabinet/wp-content/themes/healthis/images/location-icon.gif',
      scaledSize: {width: 70, height: 65, f: 'px', b: 'px',},
    };
           const markerpos = {
              lat: res.coords.latitude,
              lng: res.coords.longitude
             
        };
         this.markers.push({ position: markerpos,markerOptions:markerOptionspos,infoText: textpos});
        
        ////////////////////////////////////////////////////
        this.startLocation=markerpos;
      });
      this.selectedvisite = item;
      this.markers= [];
      this.waypnt= [];
      this.endLocation=null;

       item.events.forEach((element, index, array) => {
         
            const marker = {
              lat: Number(element.latitude),
              lng:Number(element.longitude),
             
        };
               const  markerOptions= {
      // url: 'https://www.iconpacks.net/icons/1/free-doctor-icon-313-thumb.png',
       url: require('@/images/med.png'),
      scaledSize: {width: 40, height: 35, f: 'px', b: 'px',},
    };
        const text=element.nom_client
      
        this.markers.push({ position: marker,infoText: text,markerOptions:markerOptions});

        ///////////////////////////////////////////////

                const markerend = {
              lat:36.869096908170555,
              lng:10.164238396217126,
             
        };
          this.endLocation = markerend;
         this.waypnt.push({
           location: { lat: Number(element.latitude),  lng:Number(element.longitude) },
           stopover: true,
             });

          });



    },
      toggleInfoWindow(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.infoText;
           
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
           
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;

            }
            },
    getvisites() {
      HTTP.get("visites/getvisites")
        .then((response) => {
          this.visites = response.data;
          console.log(this.visites);
        })
        .then((err) => console.log(err));
    },
    
    deletevisite(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé l'visite",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
        HTTP
            .delete("visites/deletevisite/" + id)
            .then((response) => {

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "visite supprimé",
              });

              this.getvisites();
            });
        }
      });
    },
     valide(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez confirmer l'visite",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
              let idvisite = {
   
        _id:id,
      }; 
        
            HTTP.put("visites/updatevisite/", idvisite)
            
            .then((response) => {

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "visite confermd",
              });

              this.getvisites();
            });
        }
      });
    },
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}

</style>
